//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*优惠券接口---------------------------*/
//优惠券列表接口
export function getCouponData(obj) {
    return request({
        url: '/api/admin/coupon?w_type=all',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增优惠券接口
export function postaddCoupon(obj,url) {
    return request({
        url: '/api/admin/coupon/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑优惠券接口
export function posteditCoupon(obj,url) {
    return request({
        url: '/api/admin/coupon/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除优惠券接口
export function postdelCoupon(obj,url) {
    return request({
        url: '/api/admin/coupon/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除优惠券接口
export function postdelCouponproduct(obj,url) {
    return request({
        url: '/api/admin/coupon/delProduct',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

//优惠券详情列表接口
export function getCouponLisData(obj) {
    return request({
        url: '/api/admin/coupon/getCouponList',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//获取修改优惠券数据接口
export function postCoupongetInfo(obj,url) {
    return request({
        url: '/api/admin/coupon/getInfo',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}


