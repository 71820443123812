<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
      <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
        <el-table-column  align="center"
                          type="index"
                          width="55" label="#">
        </el-table-column>
        <el-table-column prop="nickname" label="名称" min-width="120">
        </el-table-column>
        <el-table-column prop="mobile_phone" label="手机号" min-width="120">
        </el-table-column>
        <el-table-column prop="is_use" label="是否使用" width="80">
          <template #default="scope">
            <el-tag size="mini" v-if="scope.row.is_use==1">已用</el-tag>
            <el-tag size="mini" type="info" v-if="scope.row.is_use==0">未用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="get_time" label="发放时间" min-width="160">
        </el-table-column>
        <el-table-column fixed="right" prop="address" label="操作" width="110">
          <template #header>
            <el-button  @click="onUsersdata()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 10px 0">
              <el-button-group>
                <!--                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>-->
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

      </div>
    <el-drawer
        title="添加人员"
        v-model="drawer"
        size="40%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
            <div>
              <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="Userskeywords"></el-input>
              <el-button size="mini" @click="Userssearch()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
            </div>
            <div id="use1">
              <el-table @selection-change="UsersChange" max-height="450px" :data="UserskListarr" style="width: 100%;margin:10px 0 30px 0">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column prop="nickname" label="名称" min-width="120">
                </el-table-column>
                <el-table-column prop="mobile_phone" label="手机号" min-width="120">
                </el-table-column>
              </el-table>
            </div>
            <el-pagination
                small
                background
                :pager-count="5"
                @size-change="UsersSizeChange"
                @current-change="UsersCurrentChange"
                :current-page="Userspage"
                :page-sizes="[10, 20, 30, 40,50,80,100]"
                :page-size="Userslimit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="Userstotal">
            </el-pagination>
            <div style="text-align: right;margin-top: 20px">
<!--              <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>-->
              <el-button type="primary" :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
            </div>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/productapiitem"
import {getIMG, getUQIMGurl} from "@/api/common";
import {get,post} from "@/api/Api";
//本页面接口
export default {
  name: 'brand',
  props:['nowData'],
  data(){
    return{
      /*指定客户--*/
      UserskListarr:[],//指定商品组
      Userspage:1,
      Userslimit:10,
      Userstotal:0,
      Userskeywords:'',
      /*指定客户--*/
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        coupon_id:'',
        use_users:[],
      },
    }
  },
  created() {
    this.onTable()
  },

  methods:{

    /*选择人员*/
    Userssearch(){
      this.Userspage=1
      this.onUsersdata()
    },
    onUsersdata(){
      let data={
        page:this.Userspage,
        limit:this.Userslimit,
        keywords:this.Userskeywords,
      }
      get(data,'/api/admin/member?w_type=all').then((res)=>{
        if(res.code==200){
          this.UserskListarr=res.data.data
          this.Userstotal=res.data.total
          this.drawer=true
        }else {
          this.$message.success(res.msg)
        }
      }).catch(()=>{
        this.$message.success('请求失败！！')
      })
    },
    UsersSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.Userslimit=val
      this.onUsersdata()
    },
    UsersCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.Userspage=val
      this.onUsersdata()
    },
    UsersChange(val){
      this.ruleForm.use_users=[]
      let that=this
      val.forEach((item)=>{
        that.ruleForm.use_users.push({member_id:item.id})
      })
      console.log(val,that.ruleForm.use_users)
    },
    /*选择人员*/


    //上传图片方法
    httpRequest(options) {
      this.login=true
      console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'image',ext:'.'+src.substr(6)}).then((res)=>{
          console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let url=res.data.preview_url
            console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.icon=url
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })

          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      this.ruleForm.coupon_id=this.nowData.id
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        coupon_id:this.nowData.id,
      }
        get(form,'/api/admin/coupon/users').then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){

    },
    upData(row){
      this.ruleForm.name=row.name
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
          if(val==1){
            post(this.ruleForm,'/api/admin/coupon/addUsers').then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditProductTag(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
      //   } else {
      //     this.login=false
      //     console.log('error submit!!')
      //     return false
      //   }
      // })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
            post({id:row.id},'/api/admin/coupon/delUsers').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style >
#use1 .el-table__header-wrapper .el-checkbox__inner{
  display:inline-block!important;
}
</style>