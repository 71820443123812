<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        prop="id"
                         width="55" label="ID" >
      </el-table-column>
      <el-table-column min-width="120" prop="name" label="优惠券名称">
        <template #default="scope">
          <el-tag effect="dark" type="danger" size="small" >{{scope.row.name}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="send_count" min-width="80" label="发放份数">
      </el-table-column>
      <el-table-column prop="user_limit_count" min-width="80" label="每人限领">
      </el-table-column>
      <el-table-column prop="name" min-width="110" label="优惠金额">
        <template #default="scope">
          <span v-if="scope.row.price">￥ {{scope.row.price}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" min-width="110" label="满减金额">
        <template #default="scope">
          <span v-if="scope.row.order_use_limit">￥ {{scope.row.order_use_limit}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" width="80" label="类型">
        <template #default="scope">
          <span v-if="scope.row.obtain_way==0">主动领取</span>
          <span v-if="scope.row.obtain_way==1">指定发放</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" width="80" label="使用方式">
        <template #default="scope">
          <span v-if="scope.row.use_way==0">全场通用</span>
          <span v-if="scope.row.use_way==1">指定商品</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name"  width="90" label="拼团时使用">
        <template #default="scope">
          <el-tag size="mini" v-if="scope.row.with_group==1">是</el-tag>
        </template>
      </el-table-column>
        <el-table-column prop="name" width="190" label="优惠券生效时间">
          <template #default="scope">
            <span v-if="scope.row.start_time">{{scope.row.start_time}}--{{scope.row.closing_time}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" prop="address" label="操作" width="190">
          <template #header>
            <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
<!--                <el-button size="mini" type="primary"  @click="seeCou(scope.row)">优惠券详情</el-button>-->
                <el-button size="mini" type="primary" :disabled="scope.row.obtain_way==0"  @click="onUsers(scope.row)">指定用户</el-button>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    <el-drawer
        title="收费项新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar height="calc(100vh - 160px)" style="height:calc(100% - 30px) ">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
          <div class="tabForm">
            <el-form-item label="优惠券名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="优惠金额" prop="price">
              <el-input-number style="width: 100%" type="number" :precision="2" :min="0" v-model="ruleForm.price"></el-input-number>
            </el-form-item>
            <el-form-item label="发放份数" prop="send_count">
              <el-input-number style="width: 100%" type="number"  :min="0" v-model="ruleForm.send_count"></el-input-number>
            </el-form-item>
            <el-form-item label="每人限领份数" prop="user_limit_count">
              <el-input-number style="width: 100%" type="number"  :min="0" v-model="ruleForm.user_limit_count"></el-input-number>
            </el-form-item>
            <el-form-item label="优惠满减金额" prop="order_use_limit">
              <el-input-number style="width: 100%" type="number" :precision="2" :min="0" v-model="ruleForm.order_use_limit"></el-input-number>
            </el-form-item>
          </div>
          <div class="tabForm">
            <el-form-item label="有效日期" prop="coupon_date">
              <el-date-picker
                  v-model="ruleForm.coupon_date"
                  type="daterange"
                  style="width: 100%"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="获取方式" prop="obtain_way">
              <el-select style="width: 100%" v-model="ruleForm.obtain_way" placeholder="">
                <el-option
                    v-for="item in obtainArr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="使用方式" prop="use_way">
              <el-select style="width: 100%"  v-model="ruleForm.use_way" placeholder="">
                <el-option
                    v-for="item in use_wayArr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="拼团同时使用" prop="with_group">
              <div style="display: flex;justify-content: space-between;align-items:center;height: 40px">
                <el-switch
                    style="display: block"
                    v-model="ruleForm.with_group"
                    active-text="是"
                    inactive-text="否"
                    :active-value="1"
                    :inactive-value="0">
                </el-switch>
              </div>
            </el-form-item>
          </div>
          <div v-if="ruleForm.use_way==1" class="tab">
            <div style="color: #606266;display: flex;justify-content: space-between"><span>● 指定商品</span><el-button size="medium" type="text" @click="addProduct()"> 添加指定商品</el-button></div>
            <el-table  max-height="300px" :data="ruleForm.use_products" style="width: 100%;margin:10px 0 30px 0">
              <el-table-column  type="index" label="#" width="60">
              </el-table-column>
              <el-table-column prop="product_name" label="商品名称" min-width="110">
              </el-table-column>
              <el-table-column fixed="right" prop="unit" label="操作" width="57">
                <template #default="scope">
                  <el-button size="medium" type="text" @click="deletCou(scope.row,scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
<!--          <div class="tab">-->
<!--            <div style="color: #606266;display: flex;justify-content: space-between"><span>● 指定人员</span><el-button size="medium" type="text" @click="addUsers()"> 添加指定人员</el-button></div>-->
<!--            <el-table  max-height="300px" :data="ruleForm.use_users" style="width: 100%;margin:10px 0 30px 0">-->
<!--              <el-table-column  type="index" label="#" width="60">-->
<!--              </el-table-column>-->
<!--              <el-table-column prop="name" label="人员名称" min-width="110">-->
<!--                <template #default="scope">-->
<!--                  {{scope.row.nickname}}-[{{scope.row.mobile_phone}}]-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column fixed="right" prop="unit" label="操作" width="57">-->
<!--                <template #default="scope">-->
<!--                  <el-button size="medium" type="text" @click="deletUsers(scope.row,scope.$index)">删除</el-button>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--          </div>-->
      </el-form>
      </el-scrollbar>
      <div style="text-align: right">
        <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
        <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
      </div>
      <el-drawer
          title="新增指定商品"
          size="40%"
          :append-to-body="true"
          v-model="LabeDrawerCou" >
        <el-scrollbar style="height: 90%" height="600px"  >
          <div>
            <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="Coukeywords"></el-input>
            <el-button size="mini" @click="Cousearch()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
          </div>
          <el-table @select="handChange"  max-height="450px" :data="CoukListarr" style="width: 100%;margin:10px 0 30px 0">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column prop="name" label="商品名称" min-width="120">
            </el-table-column>
            <el-table-column prop="category_name" label="商品类型" min-width="80">
            </el-table-column>
          </el-table>
          <el-pagination
              small
              background
              :pager-count="5"
              @size-change="CouSizeChange"
              @current-change="CouCurrentChange"
              :current-page="Coupage"
              :page-sizes="[10, 20, 30, 40,50]"
              :page-size="Coulimit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Coutotal">
          </el-pagination>
        </el-scrollbar>
        <el-button type="primary" @click="okaddCou()">确定</el-button>
      </el-drawer>
<!--      <el-drawer-->
<!--          title="新增指定人员"-->
<!--          size="40%"-->
<!--          :append-to-body="true"-->
<!--          v-model="DrawerUsers" >-->
<!--        <el-scrollbar style="height: 90%" height="600px"  >-->
<!--          <div>-->
<!--            <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="Userskeywords"></el-input>-->
<!--            <el-button size="mini" @click="Userssearch()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>-->
<!--          </div>-->
<!--          <el-table @select="UsersChange"  max-height="450px" :data="UserskListarr" style="width: 100%;margin:10px 0 30px 0">-->
<!--            <el-table-column-->
<!--                type="selection"-->
<!--                width="55">-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="nickname" label="名称" min-width="120">-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="mobile_phone" label="手机号" min-width="120">-->
<!--            </el-table-column>-->
<!--          </el-table>-->
<!--          <el-pagination-->
<!--              small-->
<!--              background-->
<!--              :pager-count="5"-->
<!--              @size-change="UsersSizeChange"-->
<!--              @current-change="UsersCurrentChange"-->
<!--              :current-page="Userspage"-->
<!--              :page-sizes="[10, 20, 30, 40,50]"-->
<!--              :page-size="Userslimit"-->
<!--              layout="total, sizes, prev, pager, next, jumper"-->
<!--              :total="Userstotal">-->
<!--          </el-pagination>-->
<!--        </el-scrollbar>-->
<!--        <el-button type="primary" @click="okaddUsers()">确定</el-button>-->
<!--      </el-drawer>-->
    </el-drawer>
<!--    <el-drawer-->
<!--        title="优惠券详情"-->
<!--        size="50%"-->
<!--        :append-to-body="true"-->
<!--        v-model="CouponListdraw" >-->
<!--      <el-scrollbar height="calc(100vh - 160px)" style="padding:0 15px;height:calc(100% - 30px) ">-->

<!--      </el-scrollbar>-->
<!--    </el-drawer>-->
      <el-drawer
        title="指定用户"
        size="50%"
        :destroy-on-close="true"
        :append-to-body="true"
        v-model="Userdrawer">
        <UserS :nowData="rowData"></UserS>
      </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/couponApi"//本页面接口
import {postProduct} from "@/api/common"//本页面接口
import {get,post} from "@/api/Api"//本页面接口
import UserS from '../../components/couponChild/users.vue'
export default {
  name: 'brand',
  components:{
    UserS
  },
  data(){
    return{
      Userdrawer:false,
      login:false,
      drawer:false,
      CouponListdraw:false,//优惠券详情
      rowData:{
        id:'',
      },
      tableData:[],
      obtainArr:[
        {
          id:0,
          name:'主动领取',
        },
        {
          id:1,
          name:'指定发放',
        }
      ],
      use_wayArr:[
        {
          id:0,
          name:'全场通用',
        },
        {
          id:1,
          name:'指定产品',
        }
      ],
      /*商品--*/
      CoukListarr:[],//指定商品组
      CoukList:[],//指定商品
      nowCoukList:[],//选中指定商品
      LabeDrawerCou:false,//标签

      /*商品--*/

      /*指定客户--*/
      UserskListarr:[],//指定商品组
      DrawerUsers:false,//优惠券详情

      /*指定客户--*/
      page:1,
      limit:10,
      total:0,
      keywords:'',
      Coupage:1,
      Coulimit:10,
      Coutotal:0,
      Coukeywords:'',
      Userspage:1,
      Userslimit:10,
      Userstotal:0,
      Userskeywords:'',
      ruleForm:{
        name:'',
        price:'',
        send_count:'',
        user_limit_count:'',
        coupon_date:[],
        order_use_limit:'',
        obtain_way:'',
        use_way:0,
        start_time:'',
        closing_time:'',
        with_group:'',
        use_products:[],
        // use_users:[],
      },
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
        price: [
          { type:"number" ,min: 1, message: '金额大于零的整数', trigger: 'blur' },
          { required: true, message: '请填写', trigger: 'change' },
        ],
        send_count: [
          { type:"number" ,min: 1, message: '金额大于零的整数', trigger: 'blur' },
          { required: true, message: '请填写', trigger: 'change' },
        ],
        coupon_date: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        use_way: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        obtain_way: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
  },
  methods:{
    usewayFun(){
      this.ruleForm.use_products=[]
    },
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    Cousearch(){
      this.Coupage=1
      this.onProductdata()
    },
    onProductdata(){
      let data={
        page:this.Coupage,
        limit:this.Coulimit,
        keywords:this.Coukeywords,
      }
      postProduct(data).then((res)=>{
        if(res.code==200){
          this.CoukListarr=res.data.data
          this.Coutotal=res.data.total

        }
      }).catch(()=>{

      })
    },
    seeCou(row){
      this.CouponListdraw=true


    },
    /*指定商品*/
    addProduct(){
      this.onProductdata()
      this.LabeDrawerCou=true
    },
    CouSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.Coulimit=val
      this.onProductdata()
    },
    CouCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.Coupage=val
      this.onProductdata()
    },
    handChange(selection,row){
      var newNum1 = this.ruleForm.use_products.find((item, index) => {
        return item.product_id == row.id;
      })
      if(newNum1){
        console.log(newNum1)
      }else {
        this.ruleForm.use_products.push({product_id:row.id,product_name:row.name})
      }
    },
    okaddCou(){
      this.LabeDrawerCou=false
    },
    deletCou(row,i){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
              axios.postdelCouponproduct({id:row.id}).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.ruleForm.use_products.splice(i,1)
                }else {
                  this.$message.error(res.msg)
                }
              }).catch((err)=>{
                this.$message.error('服务器报错！！请联系管理员')
              })
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.ruleForm.use_products.splice(i,1)
      }

    },
    /*-----------*/



    /*指定人员*/
    /*指定人员新*/
    onUsers(row){
      this.rowData.id=row.id
      this.Userdrawer=true
    },
    /**/

    addUsers(){
      this.onUsersdata()
      this.DrawerUsers=true
    },
    Userssearch(){
      this.Userspage=1
      this.onUsersdata()
    },
    onUsersdata(){
      let data={
        page:this.Userspage,
        limit:this.Userslimit,
        keywords:this.Userskeywords,
      }
      get(data,'/api/admin/member?w_type=all').then((res)=>{
        if(res.code==200){
          this.UserskListarr=res.data.data
          this.Userstotal=res.data.total
        }
      }).catch(()=>{

      })
    },
    UsersSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.Userslimit=val
      this.onUsersdata()
    },
    UsersCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.Userspage=val
      this.onUsersdata()
    },
    UsersChange(selection,row){
      var newNum1 = this.ruleForm.use_users.find((item, index) => {
        return item.member_id == row.id;
      })
      if(newNum1){
        console.log(newNum1)
      }else {
        this.ruleForm.use_users.push({member_id:row.id,nickname:row.nickname,mobile_phone:row.mobile_phone})
      }
    },
    okaddUsers(){
      this.DrawerUsers=false
    },
    deletUsers(row,i){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
              axios.postdelCouponproduct({id:row.id}).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.ruleForm.use_users.splice(i,1)
                }else {
                  this.$message.error(res.msg)
                }
              }).catch((err)=>{
                this.$message.error('服务器报错！！请联系管理员')
              })
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.ruleForm.use_users.splice(i,1)
      }

    },
    /*-----------*/
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getCouponData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },

    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        name:'',
        price:0,
        send_count:0,
        user_limit_count:0,
        coupon_date:[],
        order_use_limit:0,
        obtain_way:0,
        use_way:0,
        start_time:'',
        closing_time:'',
        with_group:0,
        use_products:[],
        // use_users:[],
      }
      this.drawer=true
    },
    upData(row){
      axios.postCoupongetInfo({id:row.id}).then((res)=>{
        this.ruleForm.id=res.data.id
        this.ruleForm.name=res.data.name
        this.ruleForm.price=Number(res.data.price)
        this.ruleForm.send_count=Number(res.data.send_count)
        this.ruleForm.user_limit_count=Number(res.data.user_limit_count)
        this.ruleForm.coupon_date.push(res.data.start_time)
        this.ruleForm.coupon_date.push(res.data.closing_time)
        this.ruleForm.order_use_limit=Number(res.data.order_use_limit)
        this.ruleForm.obtain_way=res.data.obtain_way
        this.ruleForm.use_way=res.data.use_way
        this.ruleForm.with_group=res.data.with_group
        // if(res.data.use_products==''){
        //   console.log(1)
        //   this.ruleForm.use_products=[]
        // }else {
        //   console.log(2)
          this.ruleForm.use_products=res.data.product_list
          // this.ruleForm.use_users=res.data.use_users
        // }
      }).catch((err)=>{

      })
      this.drawer=true
    },
    okAdd(formName,val){
      this.ruleForm.start_time=this.ruleForm.coupon_date[0]
      this.ruleForm.closing_time=this.ruleForm.coupon_date[1]
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddCoupon(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditCoupon(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          that.drawer=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelCoupon({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>